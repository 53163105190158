import {formatCEP, formatCPF, getStates, isValidCEP} from '@brazilian-utils/brazilian-utils';
import ButtonWithLoading from 'components/ButtonWithLoading';
import Header from 'components/Header';
import LoadingInfo from 'components/LoadingInfo';
import Orderbump from 'components/Orderbump';
import Select from 'components/Select';
import useDataSubmission from 'hooks/useDataSubmission';
import useHDNParameters from 'hooks/useHDNParameters';
import useSubmission from 'hooks/useSubmission';
import useUTMParameters from 'hooks/useUTMParameters';
import {getPostalCode} from 'modules/customer/api';
import {CustomerActions} from 'modules/customer/redux';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useDebouncedCallback} from 'use-debounce';
import {masks} from 'utils/constants';
import {convertToCurrency, formatPhoneNumber, formValidate, formatDate} from 'utils/functions';
import {personalDataSchema, validateDataSchemaCNPJ, validateDataSchemaCPF} from 'utils/schemas';
import {useTranslation} from 'react-i18next'; //eslint-disable-line
import CustomerSelectors from 'modules/customer/selectors'; //eslint-disable-line
import WarningIcon from '../../assets/icons/WarningCircle.svg';
import Payment from '../../views/Payment';
import PaymentConfirmation from '../../views/PaymentConfirmation';
import styles from './Initial.module.scss'; //eslint-disable-line
import Logo from '../../assets/icons/cispay-logo.svg'; //eslint-disable-line
import Input from 'components/Input'; //eslint-disable-line

function Consultor() {
  const {t} = useTranslation();
  const [states, setStates] = useState([]);
  const session = useSelector(ProductSelectors.session);
  const customer = useSelector(CustomerSelectors.customer);
  const [document, setDocument] = useState('cpf'); //eslint-disable-line
  const [personalData, setPersonalData] = useState(document === 'cpf' ? Mapper.from : MapperCNPJ.from);
  const [loadingPostalCode, setLoadingPostalCode] = useState(false);
  const [formValidation, setFormValidation] = useState({});
  const [validateData, setValidateData] = useState({cpf: ''});
  const {submit} = useDataSubmission();
  const [valid, setValid] = useState(false);
  const product = useSelector(ProductSelectors.product); //eslint-disable-line
  const [data, setData] = useState({});
  const {submitExtra} = useSubmission();
  const statusPayment = useSelector(PaymentSelectors.statusPayment);
  const dispatch = useDispatch();
  const productType = useSelector(ProductSelectors.productType);
  const statusCpf = useSelector(ProductSelectors.statusCpf);
  const cpfMessage = useSelector(ProductSelectors.cpfMessage);
  const questions = useSelector(ProductSelectors.questions);
  const [values, setValues] = useState([]); //eslint-disable-line
  const errorMessage = useSelector(PaymentSelectors.getErrorMessage);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const [statusData, setStatusData] = useState(false);
  const statusPix = useSelector(PaymentSelectors.statusPix);
  const [callLeads, setCallLeads] = useState(false);
  const hdn = useSelector(ProductSelectors.getHdnOrigin);
  const discountTable = useSelector(ProductSelectors.getDiscountTable);
  const [amount, setAmount] = useState();
  const quantity = useSelector(ProductSelectors.getQuantity);
  const newValueProduct = useSelector(ProductSelectors.getNewValueProduct);
  const [formValidated, setFormValidated] = useState(true);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();
  const typeCostumer = useSelector(ProductSelectors.getTypeCustomer);
  const [statusAmount, setStatusAmount] = useState(false);

  useEffect(() => {
    if (typeCostumer === 'natural') {
      setDocument('cpf');
    } else if (typeCostumer === 'company') {
      setDocument('cnpj');
    }
  }, [typeCostumer]);

  const handleChangeExtra = async (e, field, index) => {
    e.preventDefault();
    const toClear = {};
    for (const i in product?.options) {
      if (i > index) {
        toClear[product?.options[i].key] = '';
      }
    }

    product?.product?.options?.[index].values.map(item => { //eslint-disable-line
      if (e.target.value === item.key) {
        values.push(item.label);
      }
    });

    if (statusPix?.status === 'PAYMENT_PENDING') {
      dispatch(PaymentActions.generatedPix(false));
      dispatch(PaymentActions.changePixExpired(true));
    }

    dispatch(PaymentActions.setOptions(Object.assign({...data, [field]: e.target.value})));
    setData(Object.assign({...data, [field]: e.target.value}, toClear));
    const payload = quantity > 0 ? {...data, ...session, [field]: e.target.value, ...hdn, quantityProducts: quantity} :
      {...data, ...session, [field]: e.target.value, ...hdn};
    await submitExtra(Object.assign(payload, toClear));
  };

  const handleConditionalPersonalData = () => {
    const responseCPF = !!personalData.firstName &&
      !!personalData.lastName &&
      !!personalData.email &&
      !!personalData.gender &&
      !!personalData.birthDay &&
      !!personalData.confirmEmail &&
      !!personalData.phone &&
      !!personalData.postalCode &&
      !!personalData.number &&
      !!personalData.district &&
      !!personalData.street &&
      !!personalData.city &&
      !!formValidated &&
      personalData.email === personalData.confirmEmail ? true : false;

    const responseCNPJ =
      !!personalData.corporateName &&
        !!personalData.doingBusiness &&
        !!personalData.email &&
        !!personalData.confirmEmail &&
        !!personalData.phone &&
        !!personalData.postalCode &&
        !!personalData.number &&
        !!personalData.district &&
        !!personalData.street &&
        !!personalData.city &&
        !!formValidated &&
        personalData.email === personalData.confirmEmail ? true : false;

    return document === 'cpf' ? responseCPF : responseCNPJ;
  };

  const handleChangeCPFConsultor = async e => {
    e.preventDefault();

    const {value} = e.target;

    setValidateData({cpf: value});

    if (valid) {
      dispatch(ProductActions.cpfMessage(''));
    }

    const isFormValid = await handleValidateForm(validateDataSchemaCPF, {cpf: value});

    setFormValidation(isFormValid);

    setValid(isFormValid?.isValid);

    if (value === '') {
      setStatusAmount(false);
    }

    if (isFormValid?.isValid) {
      setPersonalData(Mapper.from);
      await submit({document: value, quantityProducts: quantity > 0 ? quantity : undefined, type: 'cpf'});
      setStatusAmount(true);
    }
  };

  const handleChangeCNPJConsultor = async e => {
    e.preventDefault();

    const {value} = e.target;

    setValidateData({cnpj: value});

    if (valid) {
      dispatch(ProductActions.cpfMessage(''));
    }

    const isFormValid = await handleValidateForm(validateDataSchemaCNPJ, {cnpj: value});

    setFormValidation(isFormValid);

    setValid(isFormValid?.isValid);

    if (value === '') {
      setStatusAmount(false);
    }

    if (isFormValid?.isValid) {
      setPersonalData(Mapper.from);
      await submit({document: value, type: 'cnpj', quantityProducts: quantity > 0 ? quantity : undefined});
      setStatusAmount(true);
    }
  };

  const handleValidateForm = async (schema, validateData) => { //eslint-disable-line
    const isFormValid = await formValidate(schema, validateData);
    return isFormValid;
  };

  useEffect(() => {
    const cpfToCompare = personalData?.cpf;

    if (customer && document === 'cpf' && cpfToCompare !== customer?.document?.number) {

      setPersonalData({
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        gender: customer?.gender,
        birthDay: formatDate(customer?.birthDay),
        email: customer?.email,
        confirmEmail: customer?.email || '',
        phone: formatPhoneNumber(customer?.phone),
        cpf: customer.document?.number,

        postalCode: formatCEP(customer?.address?.postalCode),
        street: customer?.address?.street,
        number: customer?.address?.number,
        complement: customer?.address?.complement || '',
        district: customer?.address?.district,
        city: customer?.address?.city,
        state: customer?.address?.state,

        country: 'BRA',
      });

    }
  }, [customer]); //eslint-disable-line

  useEffect(() => {
    const cnpjToCompare = personalData?.cnpj;

    if (customer && document === 'cnpj' && cnpjToCompare !== customer?.document?.number) {

      setPersonalData({
        corporateName: customer?.corporateName || '',
        doingBusiness: customer?.doingBusiness || '',
        email: customer?.email || '',
        confirmEmail: customer?.email || '',
        phone: formatPhoneNumber(customer?.phone) || '',
        cnpj: validateData.cnpj.replace(/\D/g, '') || '',

        postalCode: formatCEP(customer?.address?.postalCode) || '',
        street: customer?.address?.street || '',
        number: customer?.address?.number || '',
        complement: customer?.address?.complement || '',
        district: customer?.address?.district || '',
        city: customer?.address?.city || '',
        state: customer?.address?.state || '',

        country: 'BRA',
      });

    }
  }, [customer]); //eslint-disable-line

  useEffect(() => {
    handleGetStates(personalData.country);
  }, [personalData.country]);

  const conditionalData = handleConditionalPersonalData();

  useEffect(() => {
    if (conditionalData) {
      dispatch(ProductActions.setCondPersonalData(conditionalData));
      debounced();
    } else {
      return;
    }
  }, [conditionalData]); //eslint-disable-line

  useEffect(() => {
    const validPostalCode = isValidCEP(personalData.postalCode);
    if (validPostalCode && personalData.country) {
      const postalCode = String(personalData.postalCode).replace(/[-]+/g, '');
      handlePostalCode(personalData.country, postalCode);
    }
  }, [personalData.postalCode]); // eslint-disable-line

  const handleGetStates = async () => {
    try {
      const allStates = await getStates();
      const valuesStates = allStates?.map(value => ({
        option: value?.code,
        value: value?.code,
      }));

      setStates(valuesStates);
    } catch (error) {
      setStates([]);
    }
  };

  useEffect(() => {
    let validStatus = true;
    if (formValidation.errors) {
      const keys = Object.keys(formValidation.errors);
      keys.forEach(key => {
        if (formValidation.errors[key]) {
          validStatus = false;
        }
      });
    }
    setFormValidated(validStatus);
  }, [formValidation]);

  const handlePostalCode = async (country, cep) => {
    try {
      setLoadingPostalCode(true);
      const response = await getPostalCode({country, postal_code: cep});

      setPersonalData({
        ...personalData,
        street: response?.address || personalData?.street || '',
        state: response?.state || personalData?.state || '',
        city: response?.city || personalData?.city || '',
        district: response?.district || personalData?.district || '',
      });

      const excludes = ['postalCode', 'street', 'state', 'city', 'district'];
      const auxErrors = {};
      Object.keys(formValidation.errors || []).map(key => {
        if (!excludes.includes(key)) {
          Object.assign(auxErrors, {[key]: formValidation.errors[key]});
        }
        return key;
      });

      setFormValidation({
        ...formValidation,
        errors: auxErrors,
      });
    } catch (error) {
      if (error.response.status === 404) {
        setPersonalData({
          ...personalData,
          street: personalData?.street || '',
          state: personalData?.state || '',
          city: personalData?.city || '',
          district: personalData?.district || '',
        });
      }
    } finally {
      setLoadingPostalCode(false);
    }
  };

  const debounced = useDebouncedCallback(
    () => {
      const payloadCPF = {
        ...session,
        ...Mapper.to(personalData),
      };

      const payloadCNPJ = {
        ...session,
        ...MapperCNPJ.to(personalData),
      };

      const payloadOptions = {
        ...session,
        ...data,
        ...UTMParameters,
        ...HDNParameters,
      };

      if (formValidated) {
        dispatch(CustomerActions.postLeads(document === 'cpf' ? payloadCPF : payloadCNPJ, statusPix, product, payloadOptions));
      }
    }, 300);

  const handleChange = async e => {
    e.preventDefault();

    const {id, value} = e.target;

    if (personalData[id] !== value) {
      setCallLeads(true);
    } else {
      setCallLeads(false);
    }

    setPersonalData({...personalData, [id]: value});

    if (value !== '') {
      const isFormValid = await handleValidateForm(personalDataSchema, {[id]: value});

      setFormValidation(
        {
          ...formValidation,
          errors: {
            ...formValidation.errors,
            [id]: isFormValid.errors[id],
          },
        },
      );
    }
  };

  const handdleBlue = async (e) => {
    e.preventDefault();
    const {id, value} = e.target;

    const isFormValid = await handleValidateForm(personalDataSchema, {[id]: value});

    setFormValidation(
      {
        ...formValidation,
        errors: {
          ...formValidation.errors,
          [id]: isFormValid.errors[id],
        },
      },
    );

    if (callLeads) {
      debounced();
      setCallLeads(false);
    }
  };

  const handleValidCpf = async () => {
    const isFormValid = await handleValidateForm(validateDataSchemaCPF, validateData);
    if (isFormValid?.isValid) {
      setValid(isFormValid?.isValid);
      await submit({document: validateData?.cpf, type: 'cpf'});
      setStatusAmount(true);
    }
  };

  const handleValidCnpj = async () => {
    const isFormValid = await handleValidateForm(validateDataSchemaCNPJ, validateData);
    if (isFormValid?.isValid) {
      setValid(isFormValid?.isValid);
      await submit({document: validateData?.cnpj, type: 'cnpj'});
    }
  };

  useEffect(() => {
    questions?.map((options, index) => { //eslint-disable-line
      if (Object.values(data)[index] === '') {
        setStatusData(true);
      } else {
        setStatusData(false);
      }
    });

  }, [data, questions, statusData]);

  const newDataAmount = discountTable?.map((item, idx) => ({option: idx + 1, value: item}));

  const handleAmount = async (e) => {
    setAmount(e.target.value);
    dispatch(ProductActions.setQuantity(e.target.options.selectedIndex + 1));

    await submitExtra(Object.assign({...data, ...session, quantityProducts: e.target.options.selectedIndex + 1}));
  };

  const handleChangeDocument = (e) => {

    const payloadPersonalData = {
      doingBusiness: '',
      corporateName: '',
      firstName: '',
      lastName: '',
      gender: '',
      birthDay: '',
      email: '',
      confirmEmail: '',
      street: '',
      phone: '',
      postalCode: '',
      district: '',
      number: '',
      city: '',
      cpf: '',
      cnpj: '',
    };

    setDocument(e.target.value);
    setValidateData({cpf: ''} || {cnpj: ''});
    setPersonalData(payloadPersonalData);
    setStatusAmount(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.card_payment}>
        {!statusPayment ? <div>
          <div className={styles.header}>
            <img className={styles.logo} src={Logo} alt='logo cispay' />
          </div>
          <div style={{width: '100%', height: '0.5px', backgroundColor: '#ccc', marginTop: '10px'}} />

          <div className={styles.headerSteps}>
            <Header />
          </div>

          {(productType || valid) && !statusCpf && <div className={styles.cpf}>
            {<div className={styles.documentContainer}>
              {typeCostumer === 'both' && <Select
                id='cnpj'
                name='cnpj'
                value={document}
                title='CPF/CNPJ'
                data={[{option: t('CPF').toUpperCase(), value: 'cpf'}, {option: t('CNPJ').toUpperCase(), value: 'cnpj'}]}
                onChange={e => handleChangeDocument(e)}
              />}
            </div>}
            <div className={styles.title_cpf}>
              {
                document === 'cpf' ?
                  <Input
                    title='CPF'
                    placeholder={'Valide aqui seu cpf'}
                    mask={masks?.cpfMask}
                    value={formatCPF(validateData.cpf) || ''}
                    onChange={handleChangeCPFConsultor}
                    autoFocus={true}
                    error={formValidation?.errors?.cpf}
                  /> :
                  <Input
                    title='CNPJ'
                    placeholder={'Valide aqui seu cnpj'}
                    mask={masks?.cnpjMask}
                    value={validateData.cnpj || ''}
                    onChange={handleChangeCNPJConsultor}
                    autoFocus={true}
                    error={formValidation?.errors?.cnpj}
                  />
              }
            </div>
            <ButtonWithLoading
              disabled={!valid || !!cpfMessage}
              onClick={() => document === 'cpf' ? handleValidCpf() : handleValidCnpj()}
              withStyle={true}>Proximo passo</ButtonWithLoading>

            <span className={styles.cpfMessge}>{cpfMessage}</span>
          </div>}

          {(!productType || valid) && statusCpf ? <div style={{marginTop: '20px'}} className={!product?.product ? styles.infoItemLoading : styles.infoItem}>
            {!product?.product ? <LoadingInfo /> :
            <div className={styles.cardInfoItem}>
              <div className={styles.details}>
                <span className={styles.title}>{product?.product?.name}</span>
                <span className={styles.money}>{convertToCurrency(orderBumpStatus ? product?.orderbump?.amount : newValueProduct ? newValueProduct : product?.product?.price)}</span>
              </div>

              {discountTable && <div className={styles.quantidade}>
                <span className={styles.spanQuant}>Quantidade: </span>
                <div className={styles.selectAmount}>
                  <Select
                    id='amount'
                    name='amount'
                    data={newDataAmount}
                    value={amount}
                    error={formValidation?.errors?.gender}
                    onChange={e => handleAmount(e)}
                  />
                </div>
              </div>}
            </div>}
          </div> : ''}

          {(!productType || valid) && statusCpf && <div className={styles.personalData}>

            {(!productType || valid) && <div className={styles.cpf}>
              {document === 'cpf' ?
                <Input
                  title='CPF'
                  placeholder={t('CPF')}
                  mask={masks?.cpfMask}
                  value={validateData.cpf || ''}
                  onChange={handleChangeCPFConsultor}
                  autoFocus={true}
                  error={formValidation?.errors?.cpf}
                  mandatory={true}
                  disabled={valid}
                  withoutOpacity={true}
                /> :

                <Input
                  title='CNPJ'
                  placeholder={t('CNPJ')}
                  mask={masks?.cnpjMask}
                  value={validateData.cnpj || ''}
                  onChange={handleChangeCNPJConsultor}
                  autoFocus={true}
                  error={formValidation?.errors?.cnpj}
                  mandatory={true}
                  disabled={valid}
                  withoutOpacity={true}
                />
              }
            </div>}

            <div style={{marginTop: '10px'}} className={styles.form}>
              {document === 'cpf' ? <div className={styles.grid}>
                <Input
                  id='firstName'
                  title='Nome'
                  placeholder={t('FIRST_NAME')}
                  value={personalData?.firstName || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  error={formValidation?.errors?.firstName}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
                <Input
                  id='lastName'
                  title='Sobrenome'
                  placeholder={t('LAST_NAME')}
                  value={personalData?.lastName || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  error={formValidation?.errors?.lastName}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div> :
              <div className={styles.grid}>
                <Input
                  id='corporateName'
                  title='Razão Social'
                  placeholder={'Razão Social'}
                  value={personalData?.corporateName || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  error={formValidation?.errors?.corporateName}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
                <Input
                  id='doingBusiness'
                  title='Nome Fantasia'
                  placeholder={'Nome Fantasia'}
                  value={personalData?.doingBusiness || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  error={formValidation?.errors?.doingBusiness}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>
              }

              {
                document === 'cpf' && <div className={styles.grid}>
                  <Select
                    id='gender'
                    name='personalData.gender'
                    title={t('GENDER').toUpperCase()}
                    placeholder={t('GENDER')}
                    message='Sexo'
                    data={[{option: t('MALE').toUpperCase(), value: 'M'}, {option: t('FEMALE').toUpperCase(), value: 'F'}]}
                    value={personalData?.gender || ''}
                    error={formValidation?.errors?.gender}
                    onChange={handleChange}
                    disabled={loadingPostalCode || !valid}
                    onBlur={e => handdleBlue(e)}
                    mandatory={true}
                    spacingTop={true}
                    withoutOpacity={!valid}
                    statusColor={!valid}
                  />
                  <Input
                    id='birthDay'
                    title='Data de Nascimento'
                    placeholder={t('BIRTHDAY')}
                    type='text'
                    mask={masks?.dateBirthDayMask}
                    value={personalData?.birthDay || ''}
                    disabled={!valid}
                    onChange={handleChange}
                    error={formValidation?.errors?.birthDay}
                    onBlur={e => handdleBlue(e)}
                    mandatory={true}
                    withoutOpacity={!valid}
                  />
                </div>
              }

              <div className={styles.grid}>
                <Input
                  type='email'
                  id='email'
                  name='personalData.email'
                  title={t('EMAIL').toUpperCase()}
                  placeholder={t('EMAIL')}
                  value={personalData?.email || ''}
                  onChange={handleChange}
                  disabled={!valid}
                  error={formValidation?.errors?.email}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
                <Input
                  type='email'
                  id='confirmEmail'
                  name='personalData.confirmEmail'
                  title={t('EMAIL_CONFIRMATION').toUpperCase()}
                  placeholder={t('EMAIL_CONFIRMATION')}
                  value={personalData?.confirmEmail || ''}
                  onChange={handleChange}
                  onBlur={e => handdleBlue(e)}
                  disabled={!valid}
                  error={formValidation?.errors?.confirmEmail}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>

              <div className={styles.grid}>
                <Input
                  id='phone'
                  name='personalData.phone'
                  title={t('PHONE').toUpperCase()}
                  placeholder={t('PHONE')}
                  mask={masks?.cellPhoneMask}
                  value={personalData?.phone || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  onBlur={e => handdleBlue(e)}
                  error={formValidation?.errors?.phone}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
                <Input
                  id='postalCode'
                  name='personalData.postalCode'
                  title={t('POSTAL_CODE').toUpperCase()}
                  placeholder={t('POSTAL_CODE')}
                  mask={masks?.postalCodeMask}
                  value={personalData?.postalCode || ''}
                  disabled={!valid}
                  onChange={handleChange}
                  onBlur={e => handdleBlue(e)}
                  error={formValidation?.errors?.postalCode}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>
            </div>
            <div style={{marginTop: '0px'}}>
              <div className={styles.grid}>
                <Input
                  id='street'
                  name='personalData.street'
                  title={t('ADDRESS').toUpperCase()}
                  placeholder={t('ADDRESS')}
                  value={personalData?.street || ''}
                  onChange={handleChange}
                  disabled={loadingPostalCode || !valid}
                  onBlur={e => handdleBlue(e)}
                  error={formValidation?.errors?.street}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
                <Input
                  id='number'
                  name='personalData.number'
                  title={t('NUMBER').toUpperCase()}
                  placeholder={t('NUMBER')}
                  mask={masks?.houseNumber}
                  value={personalData?.number || ''}
                  onChange={handleChange}
                  onBlur={e => handdleBlue(e)}
                  disabled={loadingPostalCode || !valid}
                  error={formValidation?.errors?.number}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>

              <div className={styles.grid}>
                <Input
                  id='complement'
                  name='personalData.complement'
                  title={t('COMPLEMENT').toUpperCase()}
                  placeholder={t('COMPLEMENT')}
                  optional={true}
                  disabled={loadingPostalCode || !valid}
                  value={personalData?.complement || ''}
                  onChange={handleChange}
                  error={formValidation?.errors?.complement}
                  withoutOpacity={!valid}
                  onBlur={e => handdleBlue(e)}
                />
                <Input
                  id='district'
                  name='personalData.district'
                  title={t('DISTRICT').toUpperCase()}
                  placeholder={t('DISTRICT')}
                  disabled={loadingPostalCode || !valid}
                  value={personalData?.district || ''}
                  onChange={handleChange}
                  error={formValidation?.errors?.district}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>

              <div className={styles.grid}>
                <div className={styles.state}>
                  <Select
                    id='state'
                    name='personalData.state'
                    title={t('STATE').toUpperCase()}
                    placeholder={t('STATE')}
                    message='Selecione o seu estado'
                    data={states}
                    value={personalData?.state || ''}
                    error={formValidation?.errors?.state}
                    onChange={handleChange}
                    disabled={loadingPostalCode || !valid}
                    onBlur={e => handdleBlue(e)}
                    mandatory={true}
                    spacingTop={true}
                    withoutOpacity={!valid}
                    statusColor={loadingPostalCode || !valid}
                  />
                </div>
                <Input
                  id='city'
                  title={t('CITY')}
                  placeholder={t('CITY')}
                  disabled={loadingPostalCode || !valid}
                  value={personalData?.city || ''}
                  onChange={handleChange}
                  error={formValidation?.errors?.city}
                  onBlur={e => handdleBlue(e)}
                  mandatory={true}
                  withoutOpacity={!valid}
                />
              </div>
            </div>

            <div style={{margin: '-5px 0px 15px'}}>
              <div className={styles.form_wrapper}>
                {questions?.map((option, index) => {
                  return (
                    <div key={index}>
                      <Select
                        key={index}
                        id={option}
                        name={option}
                        title={option}
                        message={option}
                        error={Object.values(data)[index] === '' && errorMessage}
                        statusColor={!conditionalData}
                        disabled={!conditionalData}
                        data={product?.product?.options?.[index]?.values?.map(({key, label}) => ({option: label, value: key}))}
                        value={values?.index}
                        withoutOpacity={!valid}
                        onChange={e => handleChangeExtra(e, product?.product?.options?.[index].key, index)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>

            {!conditionalData && <div className={styles.paymentMessage}>
              <img src={WarningIcon} alt='icone' />
              <span>Você deve preencher todos os dados pessoais para prosseguir com o pagamento.</span>
            </div>}

            {valid && product?.orderbump && <Orderbump />}
            {product && <Payment
              validate={!conditionalData}
              statusAmount={statusAmount}
              statusData={statusData}
              personalData={personalData}
              formValidation={formValidation}

            />}
          </div>}
        </div> : <PaymentConfirmation />}
      </div>
    </div>
  );
}

export default Consultor;

const Mapper = {
  from: () => ({
    firstName: '',
    lastName: '',
    gender: '',
    birthDay: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cpf: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalData) => ({
    customer: {
      firstName: personalData?.firstName,
      lastName: personalData?.lastName,
      gender: personalData?.gender,
      birthDay: personalData?.birthDay?.split('/')?.reverse()?.join('-'),
      email: personalData?.email.trim()?.toLowerCase(),
      phone: personalData?.phone?.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CPF',
        number: personalData?.cpf?.replace(/[.\s-]+/g, ''),
      },
      address: {
        postalCode: personalData?.postalCode?.replace(/[-]+/g, ''),
        street: personalData.street,
        number: personalData.number,
        complement: personalData.complement,
        district: personalData.district,
        city: personalData.city,
        state: personalData.state,
        country: 'Brazil',
      },
    },
  }),
};

const MapperCNPJ = {
  from: () => ({
    corporateName: '',
    doingBusiness: '',
    email: '',
    confirmEmail: '',
    phone: '',
    cnpj: '',

    postalCode: '',
    street: '',
    number: '',
    complement: '',
    district: '',
    city: '',
    state: '',

    country: 'BRA',
  }),
  to: (personalData) => ({
    customer: {
      corporateName: personalData?.corporateName,
      doingBusiness: personalData?.doingBusiness,
      email: personalData?.email.trim().toLowerCase(),
      phone: personalData?.phone?.replace(/[_().\s-]+/g, ''),
      document: {
        type: 'CNPJ',
        number: personalData?.cnpj?.replace(/[.\s-]+/g, ''),
      },
      address: {
        postalCode: personalData.postalCode.replace(/[-]+/g, ''),
        street: personalData.street,
        number: personalData.number,
        complement: personalData.complement,
        district: personalData.district,
        city: personalData.city,
        state: personalData.state,
        country: 'Brazil',
      },
    },
  }),
};
