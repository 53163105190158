import Consultor from 'components/Consultor';
import Donate from 'components/Donate';
import Footer from 'components/Footer';
import PitchComponent from 'components/PitchComponent';
import useProduct from 'hooks/useProduct';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import ErrorPage from 'views/Fail/components/ErrorPage';

function Initial() {
  const product = useSelector(ProductSelectors.product); //eslint-disable-line
  const productType = useSelector(ProductSelectors.productType);
  const {submitProduct} = useProduct();
  const dispatch = useDispatch();
  const donate = useSelector(ProductSelectors.donate);

  useEffect(() => {
    submitProduct();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (product?.product?.options?.length === 0) {
      dispatch(ProductActions.statusOptions(true));
    } else {
      return;
    }
  }, [product]); //eslint-disable-line

  const loc = useLocation();

  useEffect(() => {
    if (product?.orderbump) {
      const payload = {
        ...product,
        orderbump: {
          ...product.orderbump,
          amount: product?.product?.orderbump?.amount,
          price: product?.product?.orderbump?.price,
        },
      };
      dispatch(ProductActions.changeProduct(payload));
    }
  }, [product?.product?.orderbump?.amount]); //eslint-disable-line

  return (
    <div style={{width: '100%', height: '100%'}}>
      {
        loc?.state?.statusCode === 423 ? <ErrorPage /> : donate ? <Donate /> : productType ? <Consultor /> : <PitchComponent />
      }
      <Footer />
    </div>
  );
}

export default Initial;
