import {PaymentActions} from 'modules/payment/redux';
import ProductSelectors from 'modules/product/selectors';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {PaymentMethods} from 'utils/constants';

function useCreditCardSubmission() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const utmParams = useSelector(ProductSelectors.getUtm);

  const submit = async (payload, checkedTwoCards, status, firstProduct) => { //eslint-disable-line
    setLoading(true);
    try {
      await dispatch(PaymentActions.changePaymentMethod(PaymentMethods.CREDITCARD));
      await dispatch(PaymentActions.submitCreditCard(payload, checkedTwoCards, status, firstProduct, utmParams));
    } finally {
      setLoading(false);
    }
  };

  const changeBackForPaymentBoleto = () => {
    dispatch(PaymentActions.changeBackForPaymentBoleto(false));
  };

  return {loading, submit, changeBackForPaymentBoleto};
}

export default useCreditCardSubmission;
