import Button from 'components/Button';
import Input from 'components/Input';
import InputCreditCard from 'components/InputCreditCard';
import Select from 'components/Select';
import TryAgain from 'components/TryAgain';
import useCreditCardSubmission from 'hooks/useCreditCardSubmission';
import useHDNParameters from 'hooks/useHDNParameters';
import useUTMParameters from 'hooks/useUTMParameters';
import useWidth from 'hooks/useWidth';
import i18next from 'i18next';
import CustomerSelectors from 'modules/customer/selectors';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Switch from 'react-switch';
import {masks} from 'utils/constants';
import {convertToCurrency, formValidate} from 'utils/functions';
import {obterTokenRecaptcha} from 'utils/functions';
import {paymentSchema} from 'utils/schemas';

import styles from './credit-card.module.scss';
import usePaymentData from './hooks/usePaymentData';
import {PaymentMapper} from './mappers';

const RESPONSIVE_RESOLUTION = 720;

export default function CreditCard({statusData, validate, errorValueDonate, previewMode}) {
  const {t} = useTranslation();

  const width = useWidth();

  const productInfo = useSelector(ProductSelectors.product);
  const session = useSelector(ProductSelectors.session);
  const paymentInfo = useSelector(PaymentSelectors.payment);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const [paymentData, setPaymentData] = usePaymentData(PaymentMapper.from(paymentInfo, productInfo));
  const {submit, changeBackForPaymentBoleto} = useCreditCardSubmission();
  const [formValidation, setFormValidation] = useState({});
  const status = true; //eslint-disable-line
  const cardWithError = (paymentInfo?.backForPayment && paymentInfo?.payment?.withTwoCards)
    ? String(paymentInfo?.statusDetails?.cards?.findIndex(card => card.status === 'NOK') + 1)
    : undefined;
  const errorCredit = useSelector(PaymentSelectors.getErrorCredit);
  const dispatch = useDispatch();
  const errorDonate = useSelector(ProductSelectors.errorDonate);
  const firstProduct = useSelector(ProductSelectors.firstProduct);
  const customer = useSelector(CustomerSelectors.customer);
  const options = useSelector(PaymentSelectors.getOptions);
  const newValueProduct = useSelector(ProductSelectors.getNewValueProduct);
  const quantity = useSelector(ProductSelectors.getQuantity);
  const UTMParameters = useUTMParameters();
  const HDNParameters = useHDNParameters();
  const [loading, setLoading] = useState(false);

  const handleValidateForm = async () => {
    const isFormValid = await formValidate(paymentSchema, paymentData);
    setFormValidation(isFormValid);
    return isFormValid;
  };

  useEffect(() => {
    const installmentsB = orderBumpStatus ? productInfo?.product?.credit?.installmentsBump : productInfo?.product?.credit?.installments;
    const installments = installmentsB?.map(val => ({
      value: val.installments,
      option: `${val.installments}x ${convertToCurrency((orderBumpStatus ? productInfo?.orderbump?.amount : newValueProduct ? newValueProduct : productInfo?.product?.price) / val.installments)}`,
    }));

    setPaymentData('installments', installments);
    setPaymentData('firstCard.value', orderBumpStatus ? productInfo?.orderbump?.amount : newValueProduct ? newValueProduct : productInfo?.product?.price);
    setPaymentData('setStatusInstall', false);
  }, [productInfo, newValueProduct, orderBumpStatus]); //eslint-disable-line

  const handleChange = (e) => {
    e.preventDefault();
    const {id, value} = e.target;

    setPaymentData(id, value);

    if (value !== '') {
      setFormValidation({
        ...formValidation,
        errors: {
          ...formValidation.errors,
          [id]: undefined,
        },
      });
    }
  };

  useEffect(() => {
    if (orderBumpStatus) {
      setPaymentData('firstCard.value', productInfo?.orderbump?.amount);
      setPaymentData('setStatusInstall', false);
    } else {
      setPaymentData('firstCard.value', productInfo?.product?.price);
      setPaymentData('setStatusInstall', false);
    }

    setPaymentData('checkedTwoCards', false);
  }, [orderBumpStatus]); //eslint-disable-line

  useEffect(() => {
    if (newValueProduct) {
      setPaymentData('firstCard.value', orderBumpStatus ? productInfo?.orderbump?.amount : newValueProduct ? newValueProduct : productInfo?.product?.price);
      const installmentsB = orderBumpStatus ? productInfo?.product?.credit?.installmentsBump : productInfo?.product?.credit?.installments;
      const installments = installmentsB?.map(val => ({
        value: val.installments,
        option: `${val.installments}x ${convertToCurrency((orderBumpStatus ? productInfo?.orderbump?.amount : newValueProduct) / val.installments)} (${i18next.t('TOTAL')}: ${convertToCurrency(val?.price)})`,
      }));
      setPaymentData('installments', installments);
    } else {
      setPaymentData('firstCard.value', orderBumpStatus ? productInfo?.orderbump?.amount : productInfo?.product?.price);
    }

    setPaymentData('setStatusInstall', false);
    setPaymentData('checkedTwoCards', false);
  }, [newValueProduct, orderBumpStatus, productInfo?.orderbump?.amount]); //eslint-disable-line

  const handleSubmitForm = async e => {
    e.preventDefault();
    setLoading(true);
    const token = await obterTokenRecaptcha();
    const isFormValid = await handleValidateForm();

    if (isFormValid?.isValid) {
      const payload = {
        ...session,
        credit: {
          ...PaymentMapper.to(paymentData),
          bumpEnable: orderBumpStatus,
        },
        customer,
        token,
        options: {...options, ...UTMParameters, ...HDNParameters, quantityProducts: quantity > 0 ? quantity : undefined},
      };
      await submit(payload, paymentData?.checkedTwoCards, status, firstProduct);
      changeBackForPaymentBoleto();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (productInfo?.product?.credit?.installments.length <= 1) {
      setPaymentData('firstCard.installment', 1);
    }
  }, [productInfo?.product?.credit?.installments]); //eslint-disable-line

  return (
    <>
      {cardWithError && (
        <p className={styles.error_msg_two_cards}>{t('ERROR_MSG_TWO_CARDS')}</p>
      )}

      {errorCredit ? <TryAgain title={t('PAYMENT_FAILED_CARD')} subtitle={t('PAYMENT_FAILED_CARD_DESCRIPTION')} onClick={() => dispatch(PaymentActions.getErrorCredit(false))} /> :
      <form onSubmit={handleSubmitForm}>
        <div className={styles.form_wrapper} style={{marginTop: !productInfo?.product?.credit?.allowTwoCards && '10px'}}>
          {productInfo?.product?.credit?.allowTwoCards && !validate && <div className={styles.with_two_cards} style={{marginBottom: !paymentData?.secondCard ? '22px' : ''}}>
            <p>{t('PAY_WITH_TWO_CARDS')}</p>
            <Switch
              id='checkedTwoCards'
              name='enabled'
              disabled={validate}
              className={styles.switch}
              checked={paymentData?.checkedTwoCards}
              onChange={value => handleChange({preventDefault() {}, target: {id: 'checkedTwoCards', value}})}
              onColor='#c6e0a3'
              onHandleColor='#88bd3f'
              handleDiameter={22}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow='0px 1px 5px rgba(0, 0, 0, 0.6)'
              activeBoxShadow='0px 0px 1px 10px rgba(0, 0, 0, 0.2)'
              height={15}
              width={41}
            />
          </div>}

          <div className={styles.subTitleFirstCard} style={{display: !paymentData?.secondCard ? 'none' : 'block'}}>
            <span className={styles.oneCard}>Cartão 1</span>
            <div className={styles.separator} />
          </div>

          <section className={(cardWithError && cardWithError === '1') ? styles.border_color_error : styles.firstCardSection}>
            <div className={styles.grid}>
              <Input
                id='firstCard.holderName'
                name='paymentData.firstCard.holderName'
                title={t('HOLDER_NAME')}
                placeholder={t('HOLDER_NAME')}
                value={paymentData.firstCard.holderName}
                error={formValidation?.errors && formValidation?.errors['firstCard.holderName']}
                onChange={handleChange}
                disabled={!productInfo?.product?.credit || validate}
                withoutOpacity={!productInfo?.product?.credit}
                maxLength={25}
              />
              <Input
                id='firstCard.cpf'
                name='paymentData.firstCard.cpf'
                title={t('CPF')}
                placeholder={t('CPF')}
                mask={masks.cpfMask}
                value={paymentData.firstCard.cpf}
                error={formValidation?.errors && formValidation?.errors['firstCard.cpf']}
                onChange={handleChange}
                disabled={!productInfo?.product?.credit || validate}
                withoutOpacity={!productInfo?.product?.credit}
              />
            </div>

            <div className={styles.grid_new}>
              <div className={styles.CreditCardNumber}>
                <InputCreditCard
                  id='firstCard.cardNumber'
                  withoutOpacity={!productInfo?.product?.credit}
                  name='paymentData.firstCard.cardNumber'
                  title={t('CARD_NUMBER')}
                  placeholder={t('CARD_NUMBER')}
                  value={paymentData.firstCard.cardNumber}
                  error={formValidation?.errors && formValidation?.errors['firstCard.cardNumber']}
                  onChange={handleChange}
                  onBrand={cardBrand => handleChange({preventDefault() {}, target: {id: 'firstCard.brand', value: cardBrand}})}
                  disabled={!productInfo?.product?.credit || validate}
                  maxLength={19}
                />
              </div>

              <div className={styles.securityInfos}>
                <Input
                  id='firstCard.dueDate'
                  name='paymentData.firstCard.dueDate'
                  title={t('DUE_DATE')}
                  placeholder={t('DUE_DATE')}
                  mask={masks.dateMask}
                  value={paymentData.firstCard.dueDate}
                  error={formValidation?.errors && formValidation?.errors['firstCard.dueDate']}
                  onChange={handleChange}
                  inputWidth={'100%'}
                  normalInput={true}
                  disabled={!productInfo?.product?.credit || validate}
                  withoutOpacity={!productInfo?.product?.credit}
                />
                <Input
                  id='firstCard.securityCode'
                  name='paymentData.firstCard.securityCode'
                  title={t('CVV')}
                  fullHeight={'100%'}
                  placeholder={t('CVV')}
                  mask={masks.securityCodeMask}
                  value={paymentData.firstCard.securityCode}
                  error={formValidation?.errors && formValidation?.errors['firstCard.securityCode']}
                  onChange={handleChange}
                  inputWidth={'100%'}
                  normalInput={true}
                  disabled={!productInfo?.product?.credit || validate}
                  withoutOpacity={!productInfo?.product?.credit}
                />
              </div>
            </div>

            <div className={styles.grid}>
              <Input
                id='firstCard.value'
                name='paymentData.firstCard.value'
                title={paymentData?.checkedTwoCards ? t('PURCHASE_PRICE_CART_1') : t('PURCHASE_PRICE')}
                value={paymentData.firstCard.value}
                error={formValidation?.errors && formValidation?.errors['firstCard.value']}
                onChange={handleChange}
                stylePrice={true}
                readOnly={!paymentData?.checkedTwoCards}
                inputWidth={width >= RESPONSIVE_RESOLUTION ? '53%' : '100%'}
                currency
                disabled={!productInfo?.product?.credit || validate}
                withoutOpacity={!productInfo?.product?.credit}
              />
              <Select
                id='firstCard.installment'
                name='paymentData.firstCard.installment'
                title={t('INSTALLMENT')}
                placeholder={t('INSTALLMENT')}
                message={paymentData.installments?.length <= 1 ? '' : 'Parcela'}
                data={paymentData.installments}
                value={paymentData.firstCard.installment}
                error={formValidation?.errors && formValidation?.errors['firstCard.installment']}
                onChange={handleChange}
                inputWidth={width >= RESPONSIVE_RESOLUTION ? '55%' : '100%'}
                spacingTop={true}
                disabled={!productInfo?.product?.credit || validate}
                withoutOpacity={!productInfo?.product?.credit}
                statusColor={!productInfo?.product?.credit || validate}
              />
            </div>
          </section>

          {productInfo?.product?.credit?.allowTwoCards && (
            <>
              {paymentData?.secondCard && (
                <section className={(cardWithError && cardWithError === '2') ? styles.border_color_error : styles.firstCardSection}>
                  <div className={styles.subTitleSecondCard}>
                    <span className={styles.oneCard}>Cartão 2</span>
                    <div className={styles.separatorTwoCard} />
                  </div>
                  <div className={styles.second_card}>
                    <div className={styles.grid}>
                      <Input
                        id='secondCard.holderName'
                        name='paymentData.secondCard.holderName'
                        title={t('HOLDER_NAME')}
                        placeholder={t('HOLDER_NAME')}
                        value={paymentData?.secondCard?.holderName}
                        disabled={validate}
                        error={formValidation?.errors && formValidation?.errors['secondCard.holderName']}
                        onChange={handleChange}
                        maxLength={25}
                      />
                      <Input
                        id='secondCard.cpf'
                        name='paymentData.secondCard.cpf'
                        title={t('CPF').toUpperCase()}
                        placeholder={t('CPF')}
                        disabled={validate}
                        mask={masks.cpfMask}
                        value={paymentData?.secondCard?.cpf}
                        error={formValidation?.errors && formValidation?.errors['secondCard.cpf']}
                        onChange={handleChange}
                      />
                    </div>
                    <div className={styles.grid_new}>
                      <div className={styles.CreditCardNumber}>
                        <InputCreditCard
                          id='secondCard.cardNumber'
                          name='paymentData.secondCard.cardNumber'
                          title={t('CARD_NUMBER')}
                          inputWidth={'100%'}
                          disabled={validate}
                          placeholder={t('CARD_NUMBER')}
                          value={paymentData?.secondCard?.cardNumber}
                          mask={masks.cardNumber}
                          error={formValidation?.errors && formValidation?.errors['secondCard.cardNumber']}
                          onChange={handleChange}
                          onBrand={cardBrand => handleChange({preventDefault() {}, target: {id: 'secondCard.brand', value: cardBrand}})}
                        />
                      </div>

                      <div className={styles.securityInfos}>
                        <Input
                          id='secondCard.dueDate'
                          name='paymentData.secondCard.dueDate'
                          title={t('DUE_DATE')}
                          placeholder={t('DUE_DATE')}
                          mask={masks.dateMask}
                          disabled={validate}
                          value={paymentData?.secondCard?.dueDate}
                          error={formValidation?.errors && formValidation?.errors['secondCard.dueDate']}
                          onChange={handleChange}
                          inputWidth={'100%'}
                          normalInput={true}
                        />
                        <Input
                          id='secondCard.securityCode'
                          name='paymentData.secondCard.securityCode'
                          title={t('CVV')}
                          placeholder={t('CVV')}
                          mask={masks.securityCodeMask}
                          disabled={validate}
                          fullHeight={'100%'}
                          value={paymentData?.secondCard?.securityCode}
                          error={formValidation?.errors && formValidation?.errors['secondCard.securityCode']}
                          onChange={handleChange}
                          inputWidth={'100%'}
                          normalInput={true}
                        />
                      </div>
                    </div>

                    <div className={styles.grid}>
                      <Input
                        id='secondCard.value'
                        name='paymentData.secondCard.value'
                        disabled={validate}
                        stylePrice={true}
                        title={t('PURCHASE_PRICE_CART_2')}
                        value={paymentData?.secondCard?.value}
                        error={formValidation?.errors && formValidation?.errors['secondCard.value']}
                        readOnly={true}
                      />
                      <Input
                        id='secondCard.installment'
                        disabled={validate}
                        name='paymentData.secondCard.installment'
                        title={t('INSTALLMENT')}
                        value={paymentData?.secondCard?.installment}
                        readOnly={true}
                        style={{backgroundColor: '#fff'}}
                      />
                    </div>
                  </div>
                </section>
              )}
            </>
          )}
        </div>

        {productInfo?.product?.credit && <div className={styles.footer_wrapper}>
          <Button disabled={statusData || validate || errorDonate || errorValueDonate || previewMode} type='submit' loading={loading}>{t('CHECKOUT')}</Button>
        </div>}
      </form>}
    </>
  );
}
