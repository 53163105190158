import IconError from 'assets/images/IconError.svg';
import Button from 'components/Button';
import {PaymentActions} from 'modules/payment/redux';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import styles from './try-again.module.scss';

export default function TryAgain({title, subtitle, onClick, messagePix}) {
  const {t} = useTranslation();
  const reducedCheckout = useSelector(ProductSelectors.getReducedCheckout);
  const dispatch = useDispatch();
  const conditionalBoleto = useSelector(ProductSelectors.getConditionalBoleto);
  const conditionalButton = useSelector(ProductSelectors.getConditionalButton);

  const handleAccept = () => {
    reducedCheckout && dispatch(ProductActions.setReducedCheckout(false));
    dispatch(PaymentActions.getErrorCredit(false));
    dispatch(ProductActions.setConditionalButton(false));
    dispatch(ProductActions.setConditionalBoleto(false));
    dispatch(ProductActions.setEnabledBoleto(true));
  };

  return (
    <div className={styles.container_not_found}>
      <div className={styles.container}>
        <img src={IconError} alt='icone check' className={styles.iconeCheck} />

        <div className={styles.container_content}>
          <h3>{title}</h3>
          <div className={styles.footer_content}>
            <span>{messagePix ? messagePix : conditionalBoleto ? t('TRY_AGAIN_BOLETO') : subtitle}</span>
          </div>
        </div>
      </div>

      <div className={styles.buttons}>
        {conditionalBoleto && conditionalButton && <Button conditionalBoleto={conditionalBoleto} marginRight={true} onClick={() => handleAccept()}>Realizar pagamento com boleto</Button>}
        <Button onClick={onClick}>{conditionalBoleto && conditionalButton ? t('TRY_AGAIN_ACTION_BOLETO') : t('TRY_AGAIN_ACTION')}</Button>
      </div>
    </div>
  );
}
