import BarcodeActive from 'assets/images/BarcodeActive.svg';
import BarcodeInactive from 'assets/images/BarcodeInactive.svg';
import CreditCardIconActive from 'assets/images/CreditCard.svg';
import CreditCardIconInactive from 'assets/images/CreditCardInactive.svg';
import PixActive from 'assets/images/PixActive.svg';
import PixInactive from 'assets/images/PixInactive.svg';
import Tabs from 'components/Tabs';
import Boleto from 'modules/payment/components/Boleto';
import CreditCard from 'modules/payment/components/CreditCard';
import Pix from 'modules/payment/components/Pix';
import PaymentSelectors from 'modules/payment/selectors';
import ProductSelectors from 'modules/product/selectors';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import PaymentConfirmation from 'views/PaymentConfirmation';

import styles from './Payment.module.scss';

function Payment({personalData, formValidation, statusData, validate, errorValueDonate, statusAmount, statusPayment, previewMode}) {
  const {t} = useTranslation();
  const productInfo = useSelector(ProductSelectors.product);

  const payment = useSelector(PaymentSelectors.payment);
  const reducedCheckout = useSelector(ProductSelectors.getReducedCheckout);
  const conditionalBoleto = useSelector(ProductSelectors.getConditionalBoleto);
  const enabledBoleto = useSelector(ProductSelectors.getEnabledBoleto);

  const [activedTab, setActivedTab] = useState(payment?.backForPaymentBoleto ? 2 : 1);
  const pix = productInfo?.product?.pix?.enabled;
  const boleto = (conditionalBoleto ? !conditionalBoleto : enabledBoleto) && !reducedCheckout;
  const credit = productInfo?.product?.credit;

  const tabs = [
    {id: 1, enabled: credit, title: t('CREDIT_CARD'), icon: CreditCardIconActive, iconInactive: CreditCardIconInactive},
    {id: 2, enabled: boleto, disabled: previewMode, title: t('BOLETO'), icon: BarcodeActive, iconInactive: BarcodeInactive},
    {id: 3, enabled: pix, disabled: previewMode, title: 'PIX', icon: PixActive, iconInactive: PixInactive},
  ];

  return (
    <div className={styles.container_payment}>
      <Tabs statusAmount={statusAmount} statusData={statusData} validate={validate} errorValueDonate={errorValueDonate} tabs={tabs} activedTab={activedTab} onActive={id => setActivedTab(id)} disabled={previewMode}>
        {statusPayment ? (
          <PaymentConfirmation />
        ) : (
          <>
            {activedTab === 1 && <CreditCard statusData={statusData} validate={validate} errorValueDonate={errorValueDonate} previewMode={previewMode} />}
            {activedTab === 2 && <Boleto statusData={statusData} validate={validate} personalData={personalData} formValidation={formValidation} />}
            {activedTab === 3 && pix && <Pix statusData={statusData} validate={validate} />}
          </>
        )}
      </Tabs>
    </div>

  );
}

export default Payment;
