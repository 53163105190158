import {formatCurrency} from '@brazilian-utils/brazilian-utils';
import ProductSelectors from 'modules/product/selectors';
import moment from 'moment';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

import styles from './boleto-details.module.scss';

function BoletoDetails({boleto}) {
  const {t} = useTranslation();
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const orderBump = useSelector(ProductSelectors.orderBump);
  const product = useSelector(ProductSelectors.product);
  const newValueProduct = useSelector(ProductSelectors.getNewValueProduct);
  const statusSession = useSelector(ProductSelectors.getStatusSession);
  const session = useSelector(ProductSelectors.session);

  return (
    <>
      {boleto && (
        <div className={`${styles.container_boleto_details} ${styles.margin} ${styles.align_center}`}>
          <strong>{t('BOLETO_LINK')}: <button onClick={() => window.open(boleto?.url, 'new')}>{boleto?.url}</button></strong>
        </div>
      )}
      <div className={styles.container_boleto_details}>
        <h3>{t('INFORMATION')} <span>{t('OF_BOLETO')}</span></h3>
        <hr />
        <div className={styles.line}>
          <p>{t('PRODUCT')}</p>
          <p>{orderBumpStatus ? `${product?.product?.name} + ${orderBump?.name}` : product?.product?.name}</p>
        </div>
        {statusSession &&
          <React.Fragment>
            <hr />
            <div className={styles.line}>
              <p>{t('PRODUCT_ID')}</p>
              <p>{session?.session}</p>
            </div>
          </React.Fragment>
        }
        <hr />
        <div className={styles.line}>
          <p>{t('PURCHASE_DATE')}</p>
          <p>{moment().format('DD/MM/YYYY')}</p>
        </div>
        <hr />
        <div className={styles.line}>
          <p>{t('TOTAL_COST')}</p>
          <p className={styles.price}>R$ {formatCurrency(orderBumpStatus ? (product?.orderbump?.amount / 100) : newValueProduct ? (newValueProduct / 100) : (product?.product?.price / 100))}</p>
        </div>
      </div>
    </>
  );
}

export default BoletoDetails;
