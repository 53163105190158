const PaymentSelectors = {
  payment(state) {
    return state?.Payment;
  },

  pixExpired(state) {
    return state?.Payment?.pixExpired;
  },

  getPaymentPix(state) {
    return state?.Payment?.paymentPix;
  },

  statusPix(state) {
    return state?.Payment?.statusPix;
  },

  generatedPix(state) {
    return state?.Payment?.generatedPix;
  },

  statusPayment(state) {
    return state?.Payment?.statusPayment;
  },
  getUpsell(state) {
    return state?.Payment?.upsell;
  },
  getNewSession(state) {
    return state?.Payment?.newSession;
  },
  getErrorMessage(state) {
    return state?.Payment?.errorMessage;
  },
  getLoadingPix(state) {
    return state?.Payment?.loadingPix;
  },
  getErrorCredit(state) {
    return state?.Payment?.errorCredit;
  },
  getErrorPix(state) {
    return state?.Payment?.errorPix;
  },
  getStatusUpsell(state) {
    return state?.Payment?.statusUpsell;
  },
  getUpsellFirst(state) {
    return state?.Payment?.upsellFirst;
  },
  getOptions(state) {
    return state?.Payment?.options;
  },
  getPaymentConfirm(state) {
    return state?.Payment?.paymentConfirm;
  },
  getLoadingUpsell(state) {
    return state?.Payment?.loadingUpsell;
  },
  getSessionId(state) {
    return state?.Payment?.sessionId;
  },
  getHasUpsell(state) {
    return state?.Payment?.hasUpsell;
  },
  getValidQuestions(state) {
    return state?.Payment?.validQuestions;
  },
  getUpsellEvent(state) {
    return state?.Payment?.upsellEvent;
  },
  getDisableInputs(state) {
    return state?.Payment?.disableInputs;
  },
  getStatus(state) {
    return state?.Payment?.status;
  },
};

export default PaymentSelectors;
