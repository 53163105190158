import Bag from 'assets/images/Bag.svg';
import {PaymentActions} from 'modules/payment/redux';
import PaymentSelectors from 'modules/payment/selectors';
import {ProductActions} from 'modules/product/redux';
import ProductSelectors from 'modules/product/selectors';
import React from 'react';
import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {convertToCurrency} from 'utils/functions';

import styles from './Orderbump.module.scss';

const Orderbump = () => {
  const orderBump = useSelector(ProductSelectors.orderBump);
  const orderBumpStatus = useSelector(ProductSelectors.orderBumpStatus);
  const dispatch = useDispatch();
  const product = useSelector(ProductSelectors.product);
  const loadingPix = useSelector(PaymentSelectors.getLoadingPix);
  const disableInputs = useSelector(PaymentSelectors.getDisableInputs);
  const validQuestions = useSelector(PaymentSelectors.getValidQuestions);
  const questions = useSelector(ProductSelectors.questions);
  const validBump = useSelector(ProductSelectors.getValidBump);

  const handleOrderBump = () => {
    dispatch(ProductActions.getOrderBumpStatus(!orderBumpStatus));
    dispatch(ProductActions.setValidBump(!validBump));
    dispatch(PaymentActions.generatedPix(false));
  };

  useEffect(() => {
    const checkbox = document.getElementById('id-checkbox');
    checkbox.disabled = loadingPix || disableInputs || (questions && validQuestions);
  }, [loadingPix, disableInputs, questions, validQuestions]);

  return (
    <div className={styles.orderbump_container}>
      <div className={styles.header}>
        <img src={Bag} className={styles.imgBag} alt='imagem orderbump' />{orderBump?.name}
      </div>
      <div className={styles.opt} onClick={() => handleOrderBump()}>
        <input id='id-checkbox' className={styles.checkbox} checked={orderBumpStatus} type='checkbox' value={orderBumpStatus} onChange={() => handleOrderBump()} />
        <span className={styles.name}>
          Selecione a caixa ao lado para aproveitar a oportunidade única e garantir o
          <span className={styles.font_green}> {orderBump?.name}</span> por uma condição especial de APENAS <span className={styles.font_green}>{convertToCurrency(product?.orderbump?.price)}</span>.
        </span>
      </div>
    </div>
  );
};

export default Orderbump;
